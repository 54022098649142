var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card-title',[_vm._v(" Płatności użytkownika "),_c('v-spacer')],1),_c('v-card-title',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn-toggle',{attrs:{"mandatory":"","rounded":"","dense":""},model:{value:(_vm.toggle_one),callback:function ($$v) {_vm.toggle_one=$$v},expression:"toggle_one"}},[_c('v-btn',{attrs:{"value":false}},[_vm._v(" Nierozliczone ")]),_c('v-btn',{attrs:{"value":true}},[_vm._v(" Rozliczone ")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-spacer'),(!_vm.toggle_one)?_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.searchUnrealized),callback:function ($$v) {_vm.searchUnrealized=$$v},expression:"searchUnrealized"}}):_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.searchPayments),callback:function ($$v) {_vm.searchPayments=$$v},expression:"searchPayments"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle_one),expression:"toggle_one"}],staticClass:"elevation-1",attrs:{"headers":_vm.paymentsHeaders,"items":_vm.payments,"search":_vm.searchPayments,"loading":_vm.loadingPayments,"loading-text":"Ładowanie... Proszę czekać","options":_vm.optionsPayments,"server-items-length":_vm.totalPayments,"footer-props":{
          itemsPerPageOptions: _vm.listSize,
          showCurrentPage: true,
          disablePagination: _vm.disableFooterPayments,
        }},on:{"update:options":function($event){_vm.optionsPayments=$event}},scopedSlots:_vm._u([{key:"item.p_number",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":'/finanse/platnosc/' + item.payment_id}},[_vm._v(" "+_vm._s(item.p_number)+" ")])]}},{key:"item.t_numbers",fn:function(ref){
        var item = ref.item;
return _vm._l((item.t_numbers.split(',')),function(number,key){return _c('a',{key:key,attrs:{"href":'/transport/' + item.t_ids.split(',')[key]}},[_vm._v(" "+_vm._s(number)),(key < item.t_numbers.split(',').length - 1)?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.user_id",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":'/uzytkownicy/' + item.user_id}},[_vm._v(" "+_vm._s(item.name + ' ' + item.surname)+" ")])]}},{key:"item.date_payed",fn:function(ref){
        var item = ref.item;
return [(item.date_payed)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" Opłacone ")]):_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Nieopłacone ")])]}},{key:"item.price_net",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.price_net) + " zł"))+" ")]}},{key:"item.price_gross",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.price_gross) + " zł"))+" ")]}},{key:"item.invoice_id",fn:function(ref){
        var item = ref.item;
return [(item.invoice_id)?_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.downloadInvoice(item)}}},[_c('v-icon',[_vm._v(" mdi-file ")])],1):_vm._e()]}},{key:"item.printFull",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(" mdi-file ")])]}}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.toggle_one),expression:"!toggle_one"}],staticClass:"elevation-1",attrs:{"headers":_vm.unrealizedHeaders,"items":_vm.unrealized,"search":_vm.searchUnrealized,"loading":_vm.loadingUnrealized,"loading-text":"Ładowanie... Proszę czekać","options":_vm.optionsUnrealized,"server-items-length":_vm.totalUnrealized,"footer-props":{
          itemsPerPageOptions: _vm.listSize,
          showCurrentPage: true,
          disablePagination: _vm.disableFooterUnrealized,
        }},on:{"update:options":function($event){_vm.optionsUnrealized=$event}},scopedSlots:_vm._u([{key:"item.t_number",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":'/transport/' + item.transport_id}},[_vm._v(" "+_vm._s(item.t_number)+" ")])]}},{key:"item.s_city",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.s_postcode + ' ' + item.s_city)+" ")]}},{key:"item.r_city",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.r_postcode + ' ' + item.r_city)+" ")]}},{key:"item.priceVat",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.priceVat + item.pickup_price) + " zł"))+" ")]}},{key:"item.money_to_collect",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.money_to_collect) + " zł"))+" ")]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"dark":false,"light":true,"max-width":"410px"},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"800","outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Data zapłaty od ")]),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-date-picker',{attrs:{"color":"amber darken-1","first-day-of-week":1,"locale":"pl-PL","full-width":""},model:{value:(_vm.pickup_date_from),callback:function ($$v) {_vm.pickup_date_from=$$v},expression:"pickup_date_from"}})],1),_c('v-row',{staticClass:"ma-0 pa-auto"},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-btn',{directives:[{name:"tilt",rawName:"v-tilt"}],staticClass:"grey--text text--darken-4",attrs:{"color":"amber accent-3","x-large":true},on:{"click":function($event){_vm.from = !_vm.from;
              _vm.clearDatePicker();}}},[_vm._v(" Anuluj ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-btn',{directives:[{name:"tilt",rawName:"v-tilt"}],staticClass:"grey--text text--darken-4",attrs:{"disabled":!_vm.pickup_date_from,"color":"amber accent-3","x-large":true},on:{"click":function($event){_vm.from = !_vm.from;
              _vm.to = !_vm.to;}}},[_vm._v(" Dalej ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"dark":false,"light":true,"max-width":"410px"},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"800","outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Data zapłaty do ")]),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-date-picker',{attrs:{"color":"amber darken-1","first-day-of-week":1,"locale":"pl-PL","full-width":""},model:{value:(_vm.pickup_date_to),callback:function ($$v) {_vm.pickup_date_to=$$v},expression:"pickup_date_to"}})],1),_c('v-row',{staticClass:"ma-0 pa-auto"},[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-btn',{directives:[{name:"tilt",rawName:"v-tilt"}],staticClass:"grey--text text--darken-4",attrs:{"color":"amber accent-3","x-large":true},on:{"click":function($event){_vm.to = !_vm.to;
              _vm.clearDatePicker();}}},[_vm._v(" Anuluj ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('v-btn',{directives:[{name:"tilt",rawName:"v-tilt"}],staticClass:"grey--text text--darken-4",attrs:{"disabled":!_vm.pickup_date_to,"color":"amber accent-3","x-large":true},on:{"click":function($event){_vm.getDataPayments();
              _vm.getDataTransports();
              _vm.to = !_vm.to;}}},[_vm._v(" Zatwierdz ")])],1)],1)],1)],1),_c('InfoSnackBar',{model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}}),_c('FileFormatDialog',{ref:"fileFormatDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }